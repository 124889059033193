<script>
// import Layout from "../../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
 import { required ,maxLength} from "vuelidate/lib/validators";
// import { fetchSettings } from "../api";
// import infoStaff from './infoStaff.vue';
// import Infrastructure from './infrastructure.vue';
// import Multiselect from 'vue-multiselect'
  import Swal from "sweetalert2";

export default {

    page: {
        title: "Marque d'equipement",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: { },
    props:{
     brand: {
      type: Object,
      default: null,
    }
    },
  data() {
    return {
      title: "Nouvelle Marque d'equipement",
      typeForm: {
        name: "",
      },
       
      userStructuresTypes: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      
    };
  },
  validations: {
    typeForm: {
      name: { required, maxLength: maxLength(64) },
    },
  },

  watch:{
    brand(){
      if(this.brand){
        this.typeForm.uuid = this.brand.uuid;
        this.typeForm.name = this.brand.name;
   
      }else{
        this.clearForm();
      }
    }  
  },
  mounted(){

},

 methods:{
  
     formSubmit() {
       
      this.submitted = true;
      var valid = true;
      // var validFormData = this._validateContractAmnts();
      var router = this.$router;

      if(valid){
      this.$http
        .post("/infrastructure/equipment_brands/store", this.typeForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              Swal.fire("Félicitations!", res.data.original.msg, "success");
              setTimeout(function() {
                router.push({ name: "settings.equipment_brands.index" });
              }, 3000);
              
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
      }

    },
  }
    
}
</script>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
      <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="formrow-email-input">Désignation *</label>
                    <input
                      id="projectname"
                      v-model="typeForm.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.typeForm.name.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.typeForm.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.typeForm.name.required">La désignation est obligatoire.</span>
                      <span v-if="!$v.typeForm.name.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- </Layout> -->
</template>
